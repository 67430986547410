import React, { FC } from "react";
import Logo from "../../../assets/logo/logo.svg";
import CloseIcon from "../../../assets/icons/closeWhite.png";

interface FormModalInterface {
  setShowModal?: any;
}

const ContactModal: FC<FormModalInterface> = ({ setShowModal }) => {
  return (
    <div
      className="h-screen z-50 fixed inset-0"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      }}
    >
      <div className="container mx-auto">
        <div className="w-[90%] md:w[60%] lg:w-[40%] mx-auto bg-[#0e5b49] fixed inset-0 h-fit mt-16">
          <div className=" w-full lg:w-[90%] mx-auto pt-4 pb-12 lg:px-0 px-5">
            <div className="w-full">
              <div className="form-section pt-12 lg:pt-0 w-full">
                <div className="w-full flex justify-end">
                  <img
                    src={CloseIcon}
                    className="h-[10px] cursor-pointer absolute mr-[-14px] mt-[-5px] z-50"
                    onClick={() => setShowModal(false)}
                    alt=""
                  />
                </div>
                <div className="flex items-center justify-between pb-2">
                  <p className="text-white text-sm lg:text-3xl pb-6 w-[70%]">
                    Love to hear from you, Get in touch
                  </p>
                  <img
                    className="h-[50px] lg:h-[120px] w-fit ml-12"
                    src={Logo}
                    alt="logo"
                  />
                </div>
                <form className="w-full">
                  <div className="flex flex-col lg:flex-row justify-between w-full">
                    <div className="flex flex-col lg:mr-4 w-full">
                      <label
                        htmlFor="fname"
                        className="text-white opacity-70 text-[18px] pb-1"
                      >
                        Naam
                      </label>
                      <input
                        name="fname"
                        type="text"
                        required
                        className="bg-[#377260] text-white outline-none p-2"
                      ></input>
                    </div>
                    <div className="flex flex-col w-full">
                      <label
                        htmlFor="lname"
                        className="text-white opacity-70 text-[18px] pb-1"
                      >
                        Voornam
                      </label>
                      <input
                        name="lname"
                        type="text"
                        required
                        className="bg-[#377260] text-white outline-none p-2"
                      ></input>
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row justify-between pt-2 w-full">
                    <div className="flex flex-col lg:mr-4 w-full">
                      <label
                        htmlFor="email"
                        className="text-white opacity-70 text-[18px]  pb-1"
                      >
                        Email
                      </label>
                      <input
                        name="email"
                        type="email"
                        required
                        className="bg-[#377260] text-white outline-none p-2"
                      ></input>
                    </div>
                    <div className="flex flex-col w-full">
                      <label
                        htmlFor="phone"
                        className="text-white opacity-70 text-[18px] pb-1"
                      >
                        Telephon
                      </label>
                      <input
                        name="phone"
                        type="tel"
                        required
                        className="bg-[#377260] text-white outline-none p-2"
                      ></input>
                    </div>
                  </div>
                  <div className="flex flex-col pt-2">
                    <label
                      htmlFor="message"
                      className="text-white opacity-70 text-[18px] pb-1"
                    >
                      Uw bericht
                    </label>
                    <textarea
                      name="message"
                      className="bg-[#377260] resize-none  text-white  outline-none px-2 py-3 h-24"
                    ></textarea>
                  </div>
                  <div className="flex items-center pb-5">
                    <button
                      type="submit"
                      className="py-2 w-[30%] text-[18px] mx-auto bg-[#0e5b49] border border-2 border-white mt-3 text-white opacity-70"
                    >
                      VERSTURNE
                    </button>
                  </div>
                  <input
                    type="checkbox"
                    id="checkbox"
                    name="checkbox"
                    value="Bike"
                  />
                  <label
                    htmlFor="checkbox"
                    className="text-white opacity-60 text-[16px]"
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s
                  </label>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactModal;
