import React, { FC } from "react";
import HeroImage from "../../../assets/images/homepage/hero.png";
import logo from "../../../assets/logo/logo.svg";

interface HomePageHeroInterface {}

const HomePageHero: FC<HomePageHeroInterface> = () => {
  return (
    <section id="project" className="lg:pt-0 pt-12">
      <div
        className="flex items-center justify-center"
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "936px",
        }}
      >
        <div className="border border-2 border-white container w-full lg:w-9/12 mx-auto lg:px-0 px-2">
          <div
            className="flex lg:flex-row flex-col justify-between items-center my-4 mx-3 py-14 px-8"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <div className="w-full z-20 lg:w-[35%] mx-auto flex items-center justify-center">
              <img
                className=""
                height={250}
                width={250}
                src={logo}
                alt="logo"
              />
            </div>
            <div className="w-full lg:w-[60%] pt-8 lg:pt-0">
              <h3 className="text-[#0e5b49] font-bold text-[26px]">
                {" "}
                Gezelling women in Wemmel
              </h3>
              <p className="py-3 text-[18px]">
                De Residentie bestaat uit een gebouw met 10 wooneenheden gespreid over 3 bouwlagen en een ondergrondse parkeergarage. Elk appartement beschikt over een ruim terras.
              </p>
              <p className="py-3 text-[18px]">
                De appartementen zijn kwaliteitsvol ingedeeld en afgewerkt en bieden zodoende een groot comfort aan de bewoners. Het gebruik van duurzame materialen en technieken garandeert een laag energieverbruik en maken van de appartementen een uiterst toekomstgerichte investering.
              </p>
              <p className="py-3 text-[18px]">
                Het perceel wordt verzorgd groen ingekleed met de nodige aandacht voor de privacy van de bewoners, en omwonenden.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageHero;
