import React, { FC, useState, useEffect } from "react";
import { record } from "../../../assets/data/data";
import logo from "../../../assets/logo/Logo-apparence.svg";

interface DuurzaamInterface {}

const HomePageDuurzzam: FC<DuurzaamInterface> = () => {
  const [listFilter, setListFilter] = useState("parking");
  const [list, setList] = useState(record);
  const [selectedRecord, setSelectedRecord]: any = useState({
    name: "Parking1(Box)",
    price: "35.000",
    type: "parking",
  });

  useEffect(() => {
    eventsFilterHandler("appartementen");
  }, []);

  const eventsFilterHandler = (val: any) => {
    let _events = [...record];

    if (val === "parking") {
      _events = _events.filter((item) => item.type === "parking");
    }

    if (val === "appartementen") {
      _events = _events.filter((item) => item.type === "appartementen");
    }

    if (val === "bergingen") {
      _events = _events.filter((item) => item.type === "bergingen");
    }

    setListFilter(val);
    setList(_events);
    setSelectedRecord(_events[0]);
  };

  const selectRecordHandler = (name: string) => {
    let filteredRecord = [...list].filter((item) => item.name === name);
    setSelectedRecord(filteredRecord[0]);
    // console.log("filteredRecord",filteredRecord)
  };

  return (
    <section
      id="appartementen"
      className="container w-full lg:w-9/12 mx-auto pt-12 lg:pt-24 px-4 lg:px-0"
    >
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <h3 className="text-[#295A49] font-bold text-[46px] pb-4">
          Appartementen
        </h3>
        {listFilter === "appartementen" && (
          <a download href={selectedRecord?.pdf} target="_blank" rel="noreferrer">
            <button
              className={`w-full my-1 lg:my-0 px-12 text-[14px] lg:text-[18px] py-2 border border-[#36594A] text-[#36594A] hover:bg-[#36594A] hover:text-[#fff]`}
            >
              DOWNLOADEN
            </button>
          </a>
        )}
      </div>
      <div className="appartementen flex flex-col lg:flex-row  justify-between">
        <div className="w-full lg:w-[50%]">
          <div className="flex flex-col lg:flex-row justify-between">
            <button
              className={`w-full my-1 lg:my-0 lg:w-[32%] text-[14px] lg:text-[18px] py-2 border  ${
                listFilter === "appartementen"
                  ? "bg-[#C4A469] text-white"
                  : "bg-white text-[#cca160]"
              }`}
              onClick={() => eventsFilterHandler("appartementen")}
            >
              Appartementen
            </button>
            <button
              className={`w-full my-1 lg:my-0 lg:w-[32%] text-[14px] lg:text-[18px] py-2 border ${
                listFilter === "parking"
                  ? "bg-[#C4A469] text-white"
                  : "bg-white text-[#cca160]"
              }`}
              onClick={() => eventsFilterHandler("parking")}
            >
              Parkeerplaatsen
            </button>
            <button
              className={`w-full my-1 lg:my-0 lg:w-[32%] text-[14px] lg:text-[18px] py-2 border ${
                listFilter === "bergingen"
                  ? "bg-[#C4A469] text-white"
                  : "bg-white text-[#cca160]"
              }`}
              onClick={() => eventsFilterHandler("bergingen")}
            >
              Bergingen
            </button>
          </div>
          <div className="bg-gray-200 px-3 py-4 mt-5">
            <div className="h-[400px] overflow-y-scroll pr-3">
              <ul>
                {list?.map((single, index) => {
                  return (
                    <li className="list-inner mb-2" key={index}>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          selectRecordHandler(single.name);
                        }}
                        className={`flex justify-between border border-2 border-transparent ${
                          single.name === selectedRecord.name
                            ? "border-[#0e5b49]"
                            : ""
                        } w-full bg-white py-4 px-2`}
                      >
                        <p className="text-[#577C67] text-left w-4/12">
                          {single?.name}
                        </p>
                        {listFilter === "appartementen" ? (
                          <p className=" text-right w-8/12 px-2">
                            <span className="text-[#062319]">
                              {single?.location} <br />
                            </span>
                            <span className="text-[#577C67]">
                              {single?.price == "Verkocht" ? single?.price : "&#8364;"+single?.price}
                            </span>
                          </p>
                        ) : (
                          <p className="text-[#577C67] text-right  w-8/12">
                            <span className="text-[#062319]">{single?.op ? single?.op+" m2 • ":null}</span> &#8364; {single?.price}
                          </p>
                        )}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-[48%] flex items-center px-2 py-8 lg:py-0 justify-center">
          {listFilter === "appartementen" ? (
            <img
              className="h-full shadow-lg border"
              src={selectedRecord.img}
              alt="logo"
            />
          ) : (
            <img className="" height={450} width={450} src={logo} alt="logo" />
          )}
        </div>
      </div>
      <div className="pt-12">
        <p className="text-[#cca160] text-[14px] leading-relaxed">
          * Voorbehoud: De vermelde prijzen zijn indicatief en niet bindend.
          Omwille van de sterk fluctuerende prijzen in de bouwsector kunnen de
          prijzen op elk ogenblik worden aangepast. Evenwel zal de koper kunnen
          genieten van een vaste prijs van zodra een verkoopovereenkomst wordt
          getekend.
        </p>
        <p className="text-[#cca160] text-[14px] leading-relaxed pt-4">
          Zijn niet inbegrepen in de prijs: alle belastingen, taksen en
          heffingen van welke aard ook, de kosten van de Statuten, alle
          nutsaansluitingen, de verlichtingstoestellen van de appartementen, het
          binnenschilderwerk in de privéruimte, de telefooninstallatie, het
          vaststaande of niet vaststaande meubilair en de decoratiewerken.
        </p>
      </div>
    </section>
  );
};

export default HomePageDuurzzam;
